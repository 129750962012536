export enum bookingTypes {
    FETCH_MANAGEMENT_REQUEST = 'FETCH_MANAGEMENT_REQUEST',
    FETCH_BOOKING_REQUEST = 'FETCH_BOOKING_REQUEST',
    FETCH_BOOKING_BY_ID = 'FETCH_BOOKING_BY_ID',
    CREATE_BOOKING_REQUEST = 'CREATE_BOOKING_REQUEST',
    EDIT_BOOKING_REQUEST = 'EDIT_BOOKING_REQUEST',
    DELETE_BOOKING_REQUEST = 'DELETE_BOOKING_REQUEST',
    SET_MANAGEMENT = 'SET_MANAGEMENT',
    SET_BOOKINGS = 'SET_BOOKINGS',
    SET_BOOKING = 'SET_BOOKING',
    SET_SCHEDULE = 'SET_SCHEDULE',
    SET_RELATIVE_BOOKING = 'SET_RELATIVE_BOOKING',
    GET_BILLING_REQUEST = 'GET_BILLING_REQUEST',
    SET_BILLING_REQUEST = 'SET_BILLING_REQUEST',
    GET_LOCATIONS_REQUEST = 'GET_LOCATIONS_REQUEST',
    GET_LOCATION_REQUEST = 'GET_LOCATION_REQUEST',
    CREATE_LOCATION_REQUEST = 'CREATE_LOCATION_REQUEST',
    SET_LOCATIONS = 'SET_LOCATIONS',
    SET_LOCATION = 'SET_LOCATION',
    UPDATE_LOCATION_REQUEST = 'UPDATE_LOCATION_REQUEST',
    EDIT_LOCATION = 'EDIT_LOCATION',
    DELETE_LOCATION = 'DELETE_LOCATION',
}
